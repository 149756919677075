<template>
  <div class="nexo-transaction-details-container wrapper">
    <CRow class>
      <CCol lg="12" md="12">
        <CCard>
          <CCardHeader>
            <router-link :to="nexoTransactionListUrl">
              <CIcon name="cil-arrow-left" class="mr-1" />
              {{ $t('NEXO_TRANSACTION_DETAILS_PAGE_BACK_LINK_BUTTON') }}
            </router-link>
          </CCardHeader>
          <CCardBody v-if="isLoading">
            <vcl-list></vcl-list>
          </CCardBody>
          <CCardBody v-if="!isLoading">
            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_TRANSACTION_EMAIL_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext"> {{ nexoTransactionDetails.email }}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_STATUS_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <StakingBadge type="nexo-transaction-status" :text="nexoTransactionDetails.status" />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_DEVICE_CODE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ nexoTransactionDetails.device_code }}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_TRANSACTION_TYPE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                  <div class="form-control-plaintext">
                  <StakingBadge type="nexo-transaction-type" :text="nexoTransactionDetails.type" />
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_PLATFORM_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <span>{{ nexoTransactionDetails.platform }}</span>
                </div>
              </CCol>

              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_DATE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <div class="form-control-plaintext">
                  <span>{{ nexoTransactionDetails.createdAt | moment(dateFormat) }}</span>
                </div>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_AMOUNT_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ nexoTransactionDetails.amount }}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_TOTAL_FEE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ nexoTransactionDetails.total_fee }}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_ADDRESS_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ nexoTransactionDetails.address }}</span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_TX_ID_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ nexoTransactionDetails.tx_id }}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_MEMO_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ nexoTransactionDetails.memo }} </span>
              </CCol>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_SHORT_NAME_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ nexoTransactionDetails.short_name }}</span>
              </CCol>
            </CRow>

            <CRow>
              <CCol lg="2" md="2">
                <label class="col-form-label">{{ $t('NEXO_TRANSACTION_DETAILS_PAGE_RESPONSE_LABEL') }}</label>
              </CCol>
              <CCol lg="4" md="4">
                <span class="form-control-plaintext">{{ nexoTransactionDetails.response }}</span>
              </CCol>
            </CRow>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import _ from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { VclList } from 'vue-content-loading';
import endpoints from '@/constants/endpoints';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import currencyHelper from '@/utils/currency-helper';
import { Constants, env } from '@/constants';
import StakingBadge from '@/components/StakingBadge';

export default {
  name: 'EditNexoTransaction',
  components: {
    VclList,
    StakingBadge,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      nexoTransactionId: null,
      nexoTransactionDetails: null,
      nexoTransactionListUrl: '/admin/exchange/nexo-transactions',
      isLoading: true,
    };
  },
  async mounted() {
    this.nexoTransactionId = this.$route.params.id;
    await this.getNexoTransactionsDetail(this.nexoTransactionId);
    this.isLoading = false;
  },
  methods: {
    backToNexoTransactionList() {
      this.$router.push(this.nexoTransactionListUrl);
    },

    async getNexoTransactionsDetail(nexoTransactionId) {
      try {
        const res = await this.$http.get(endpoints.getNexoTransactionsDetail(this.nexoTransactionId));
        this.nexoTransactionDetails = res.data;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('NEXO_TRANSACTION_DETAILS_PAGE_NOTIFICATION_ERROR_TITLE'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
  },
};
</script>

<style lang="scss">
.nexo-transaction-details-container {
  &.wrapper {
    padding: 0 24px;
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #695fe5;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 16px;
        cursor: pointer;
      }
    }

    .nexo-transaction-type,
      .nexo-transaction-status {
        background-color: transparent;
        padding: 0px;
      }
  }
}
</style>
